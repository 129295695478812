.ant-btn-background-ghost {
  color: black !important;
  border-color: black !important;
}

.ant-btn-background-ghost:focus,
.ant-btn-background-ghost:hover {
  color: white !important;
  border-color: var(--palette-maroon) !important;
  background-color: var(--palette-maroon) !important;
}

.ant-btn-primary {
  color: white !important;
  background-color: var(--palette-maroon) !important;
  border-color: var(--palette-maroon) !important;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background-color: rgb(160, 60, 27) !important;
  border-color: rgb(160, 60, 27) !important;
}

.ant-btn-icon-only {
  color: white !important;
  background-color: var(--palette-maroon) !important;
  border-color: var(--palette-maroon) !important;
}

.ant-btn-icon-only:hover,
.ant-btn-icon-only:focus {
  background-color: rgb(160, 60, 27) !important;
  border-color: rgb(160, 60, 27) !important;
}

.ant-form-item-label
  label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: var(--palette-maroon);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--title-color) !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--title-color);
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--title-color);
}

.ant-input:focus {
  border-color: var(--title-color);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.ant-input:hover {
  border-color: var(--title-color);
}
