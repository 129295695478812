@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&family=Sawarabi+Mincho&family=Rock+Salt&display=swap');

:root {
  --main-font: 'IBM Plex Serif', serif;
  --title-font: 'Sawarabi Mincho', sans-serif;
  --palette-maroon: rgb(120, 41, 15);
  --background-color: #f5f3f5;
  --title-color: #15616d;
  --border-color: rgb(255, 125, 0);
}

/* assign fonts */

h1,
h2,
h3 {
  font-family: var(--title-font);
}

p {
  font-family: var(--main-font);
}

.text {
  font-family: var(--main-font);
}

.page-title {
  text-align: center;
  font-size: 60px;
  color: var(--title-color);
  margin-bottom: 0px;
}

.fade-in {
  animation: fade-in 300ms ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
