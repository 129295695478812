.event-location {
  font-size: 20px;
  text-decoration: none;
  color: var(--palette-maroon);
  transition: all 0.3s;
}

.event-location:hover {
  color: black;
}
