:root {
  --teaching-image-height: 75vh;
}

.lessons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  margin: 0 10vw;
}

.lessons-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: var(--teaching-image-height);
}

.lessons-info {
  width: 30vw;
  min-height: 20vh;
  text-align: center;
  font-size: 18px;
  background-color: white;
  border: 5px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;
}

.lessons-info-container > :first-child {
  text-align: start;
  font-weight: bold;
}

.lessons-image {
  background-color: black;
  height: var(--teaching-image-height);
  width: 30%;
  min-width: 350px;
  border: 5px solid var(--border-color);
  object-fit: cover;
}

@media (max-width: 1080px) {
  .lessons-container {
    flex-direction: column;
    align-items: center;
  }

  .lessons-info-container {
    height: 100vh;
    justify-content: space-evenly;
  }

  .lessons-info {
    width: 60vw;
  }

  .lessons-image {
    width: 80vw;
    heigth: auto;
    max-width: auto;
  }
}
